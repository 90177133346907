import React from "react";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Img from "../Image";
import { Link } from "react-router-dom";

const FooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-top: 2px solid #fff;
  background-color: #222831;
  ${
    "" /* background-color: #FADBB5;
  color: #222831; */
  }
  img {
    padding: 8px;
  }
  ul {
    padding: 8px;
  }

  ul,
  li,
  a {
    line-height: 30px;
    text-transform: uppercase;
    font-size: 13px;
  }
  b {
    font-size: 18px;
  }
`;

export default function index() {
  return (
    <FooterWrapper>
      <Container className="py-[80px]">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Grid item xs={12} md={12}>
              <a href="/">
                <Img src="/logohorizontalwhite.webp" class="w-full h-full" />
              </a>
            </Grid>
            <Grid item xs={12} md={12}>
              <ul>
                <li>
                  <Link
                    onClick={() => window.reload()}
                    to="/"
                    class="underline"
                  >
                    <b>Home</b>
                  </Link>
                </li>
                <li>
                  <Link onClick={() => window.reload()} to="/über-uns">
                    über Uns
                  </Link>
                </li>
                <li>
                  <Link onClick={() => window.reload()} to="/privatkunden">
                    Privatkunden
                  </Link>
                </li>
                <li>
                  <Link onClick={() => window.reload()} to="/geschäftskunden">
                    geschäftskunden
                  </Link>
                </li>
                <li>
                  <Link onClick={() => window.reload()} to="/kontakt">
                    kontakt
                  </Link>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            className="flex  justify-center max-md:inline-block"
          >
            <Grid item xs={12} md={6}>
              <ul>
                <li>
                  <Link
                    onClick={() => window.reload()}
                    to="/privatkunden"
                    class="underline"
                  >
                    <b>PRIVATKUNDEN</b>
                  </Link>
                </li>
                <li>
                  <Link onClick={() => window.reload()} to="/privatkunden">
                    #STEUERERK-LÄRUNG
                  </Link>
                </li>
                <li>
                  <Link onClick={() => window.reload()} to="/privatkunden">
                    #BEWERBUNGEN (ALK) MENTOR & COACHING
                  </Link>
                </li>
                <li>
                  <Link onClick={() => window.reload()} to="/privatkunden">
                    #UNTERSTÜTZUNG UND BERATUNG BEIM BEHÖRDENGANG
                  </Link>
                </li>
                <li>
                  <Link onClick={() => window.reload()} to="/privatkunden">
                    #PRÄMIENVERBILIGUNGEN
                  </Link>
                </li>
                <li>
                  <Link onClick={() => window.reload()} to="/privatkunden">
                    #PRIVATE BUDGETPLANNUNG & SCHULDENMANAGEMENT
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6}>
              <div>
                <ul>
                  <li>
                    <Link
                      onClick={() => window.reload()}
                      to="/geschäftskunden"
                      class="underline"
                    >
                      <b>GESCHÄFTSKUNDEN</b>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => window.reload()} to="/geschäftskunden">
                      #FINANZBUCHHALTUNG
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => window.reload()} to="/geschäftskunden">
                      #STEUERBERATUNG
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => window.reload()} to="/geschäftskunden">
                      #Mehrwertsteuer - MWST
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => window.reload()} to="/geschäftskunden">
                      #JAHRESABSCHLUSS
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => window.reload()} to="/geschäftskunden">
                      #LOHNBUCHHALTUNG UND PERSONAL-ADMINISTRATION
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => window.reload()} to="/geschäftskunden">
                      #FIRMENGRÜNDUNG
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => window.reload()} to="/geschäftskunden">
                      #SOZIALVERSICHERUNGEN
                    </Link>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12} md={12}>
        <div class="w-full h-full py-10 bg-[#202634] flex align-center justify-between max-lg:text-center ">
          <Container>
            <div className="flex items-center justify-between max-md:block">
              <div>
                © {new Date().getFullYear()} DS-FINANCE | ALLE RECHTE
                VORBEHALTEN |
                <a target="_blank" rel="noreferrer" href="https://w1a.ch/">
                &nbsp; W1A Webagentur
                </a>
              </div>
              <div>
                <Link onClick={() => window.reload()} to="/impresum">
                  IMPRESUM
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </Grid>
    </FooterWrapper>
  );
}
