import React from "react";
import Img from "../Image";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Atropos from "atropos/react";
import "atropos/css";

function Home() {
  return (
    <>
      <Grid
        container
        spacing={2}
        className="card rounded-none border-0 flex items-center justify-center m-auto w-full h-screen py-[120px]"
      >
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={5}>
          <div id="app">
            <Atropos
              shadow={false}
              onEnter={() => console.log("Enter")}
              onLeave={() => console.log("Leave")}
              onRotate={(x, y) => console.log("Rotate", x, y)}
              alwaysActive={true}
              highlight={true}
            >
              <div>
                <p class="font-medium max-lg:text-[32px] text-[52px] max-md:text-center   md:text-left  m-auto p-[10px]  ">
                  Als Experte rund um die Mehrwertsteuer sind Sie bei uns an der
                  richtigen Adresse.
                </p>
              </div>
              <div class="flex align-center justify-start max-lg:justify-center  m-auto z-0 p-[10px]">
                <Link
                  style={{
                    padding: "12px 32px",
                    border: "2px solid #0e6fa0",
                    color: "#fff",
                    zIndex: "0",
                    borderRadius: "8px",
                  }}
                  to="/privatkunden"
                >
                  Privatkunden
                </Link>
                <Link
                  to="/geschäftskunden"
                  style={{
                    padding: "12px 32px",
                    marginLeft: "15px",
                    border: "2px solid #0e6fa0",
                    color: "#fff",
                    zIndex: "0",
                    borderRadius: "8px",
                  }}
                >
                  Geschäftskunden
                </Link>
              </div>
            </Atropos>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Img
            src="coin-background-min.webp"
            alt="coin"
            width="100%"
            height="100%"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Home;
