import React from "react";
import "./404.css";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Test = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export default function Error() {
  return (
    <>
      <Test>
        <div class="container-title">
          <div class="title">
            <div class="number">4</div>
            <div class="moon">
              <div class="face">
                <div class="mouth"></div>
                <div class="eyes">
                  <div class="eye-left"></div>
                  <div class="eye-right"></div>
                </div>
              </div>
            </div>
            <div class="number">4</div>
          </div>
          <div class="subtitle">Hoppla. Sieht aus, als wären Sie falsch abgebogen.</div>
          <button className="py-[12px] px-[32px] mt-10">
            <Link to="/">Nach Hause gehen !</Link>
          </button>
        </div>
      </Test>
      <div class="container container-star">
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-1"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
        <div class="star-2"></div>
      </div>
      {/* <div class="container container-bird">
        {/* <div class="bird bird-anim">
          <div class="bird-container">
            <div class="wing wing-left">
              <div class="wing-left-top"></div>
            </div>
            <div class="wing wing-right">
              <div class="wing-right-top"></div>
            </div>
          </div>
        </div> */}
      {/* <div class="bird bird-anim">
          <div class="bird-container">
            <div class="wing wing-left">
              <div class="wing-left-top"></div>
            </div>
            <div class="wing wing-right">
              <div class="wing-right-top"></div>
            </div>
          </div>
        </div> */}
      {/* <div class="bird bird-anim">
          <div class="bird-container">
            <div class="wing wing-left">
              <div class="wing-left-top"></div>
            </div>
            <div class="wing wing-right">
              <div class="wing-right-top"></div>
            </div>
          </div>
        </div>
        <div class="bird bird-anim">
          <div class="bird-container">
            <div class="wing wing-left">
              <div class="wing-left-top"></div>
            </div>
            <div class="wing wing-right">
              <div class="wing-right-top"></div>
            </div>
          </div>
        </div>
        <div class="bird bird-anim">
          <div class="bird-container">
            <div class="wing wing-left">
              <div class="wing-left-top"></div>
            </div>
            <div class="wing wing-right">
              <div class="wing-right-top"></div>
            </div>
          </div>
        </div>
        <div class="bird bird-anim">
          <div class="bird-container">
            <div class="wing wing-left">
              <div class="wing-left-top"></div>
            </div>
            <div class="wing wing-right">
              <div class="wing-right-top"></div>
            </div>
          </div>
        </div> */}
      {/* </div> */}
    </>
  );
}
