import React, { useRef } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import styled from "styled-components";
import { useInView } from "framer-motion";

const KontaktWrapper = styled.div`
  background: url("/kontaktbg-min.webp"),
    linear-gradient(rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0.66));
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 120px;

  h1 {
    font-size: 32px;
    text-align: center;
  }
`;

function Section({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {children}
      </span>
    </section>
  );
}

function ContactForm() {
  return (
    <KontaktWrapper>
      <Container>
        <Grid container spacing={2} className="py-20">
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={5}>
            <Grid
              container
              spacing={2}
              className="flex items-center justify-between m-auto w-full h-full"
            >
              <Grid item xs={12} md={12}>
                <Section>
                  <h1>Phone</h1>
                  <div className="card p-10 flex items-center justify-start">
                    <div>
                      <svg
                        width="46"
                        height="46"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m22 5-3 3m-3-3h6-6Zm6 0-3-3 3 3Z"></path>
                        <path d="M18.119 14.702 14 15.5c-2.781-1.396-4.5-3-5.5-5.5l.77-4.13L7.815 2H4.064c-1.128 0-2.016.932-1.847 2.047.42 2.783 1.66 7.83 5.283 11.453 3.806 3.805 9.286 5.456 12.302 6.113 1.165.253 2.198-.655 2.198-1.848v-3.584l-3.881-1.479Z"></path>
                      </svg>
                    </div>
                    <div>
                      <div className=" items-center ml-14 ">
                        <p> ~ Sanja Marku</p>
                        <a href="tel:+41 76 346 86 24">+41 76 346 86 24</a>
                      </div>
                      <br />
                      <div className="items-center ml-14 ">
                        <p> ~ Drita Laskaj</p>
                        <a href="tel:+41 76 424 33 47">+41 76 424 33 47</a>
                      </div>
                    </div>
                  </div>
                </Section>
              </Grid>
              <Grid item xs={12} md={12}>
                <Section>
                  <h1>Mail</h1>
                  <div className="card p-10 flex items center justify-start">
                    <div>
                      <svg
                        width="46"
                        height="46"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="m7 9 5 3.5L17 9"></path>
                        <path d="M2 17V7a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2Z"></path>
                      </svg>
                    </div>

                    <div className="flex items-center ml-14">
                      <a href="mailto:info@ds-finance.ch">info@ds-finance.ch</a>
                    </div>
                  </div>
                </Section>
              </Grid>
              <Grid item xs={12} md={12}>
                <Section>
                  <h1>Location</h1>
                  <div className="card p-10 flex items center justify-start">
                    <div>
                      <svg
                        width="46"
                        height="46"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M15 5 9 3m0 16-5.21 1.737a.6.6 0 0 1-.79-.57V5.433a.6.6 0 0 1 .41-.569L9 3v16Zm0 0 6 2-6-2Zm0 0V3v16Zm6 2 5.59-1.863a.6.6 0 0 0 .41-.57V3.832a.6.6 0 0 0-.79-.569L15 5v16Zm0 0V5v16Z"></path>
                      </svg>
                    </div>
                    <div className="flex items-center ml-14 ">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.google.com/maps/place/Meierh%C3%B6flistrasse+3,+6020+Emmen,+Zvic%C3%ABr/@47.071347,8.2871473,19z/data=!4m13!1m7!3m6!1s0x478ffc9e3b589e1d:0x7f9eb4613b784bd7!2sMeierh%C3%B6flistrasse+3,+6020+Emmen,+Zvic%C3%ABr!3b1!8m2!3d47.0714374!4d8.2878903!3m4!1s0x478ffc9e3b589e1d:0x7f9eb4613b784bd7!8m2!3d47.0714374!4d8.2878903"
                      >
                        Meierhöflistrasse 3, 6020, Emmenbrücke
                      </a>
                    </div>
                  </div>
                </Section>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid container spacing={0}>
        <Grid item xs={12} md={12}>
          <Section>
            <iframe
              title="ds-finance location"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=Meierh%C3%B6flistrasse%203,%206020,%20Emmenbr%C3%BCcke&t=k&z=15&ie=UTF8&iwloc=&output=embed"
              width="100%"
              height="400px"
            ></iframe>
          </Section>
        </Grid>
      </Grid>
    </KontaktWrapper>
  );
}

export default ContactForm;
