import React from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import Container from "@mui/material/Container";

import { useRef } from "react";
import { useInView } from "framer-motion";

const GeschäftskundenWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 120px 0px;
  h1 {
    text-align: center;
  }
  .card h1 {
    font-size: 32px;
    font-weight: bold;
    padding-bottom: 10px;
    text-align: left;
    @media (max-width: 640px) {
      font-size: 25px;
    }
  }
  .card p {
    @media (max-width: 640px) {
      font-size: 20px;
    }
  }
  .card {
    padding: 30px;
  }

  .bg-color {
    position: absolute;
    width: 1070px;
    height: 1016px;
    left: -179px;
    top: 400px;
    background: #41ead4;
    opacity: 0.11;
    filter: blur(125px);
    @media (max-width: 1024px) {
      width: 0px;
      height: 0px;
    }
  }
  .bg-color1 {
    position: absolute;
    width: 1070px;
    height: 1016px;
    left: 179px;
    top: 1140px;
    background: #be41ea;
    opacity: 0.11;
    filter: blur(125px);
    @media (max-width: 1024px) {
      width: 0px;
      height: 0px;
    }
  }
  .bg-color1 {
    position: absolute;
    width: 1070px;
    height: 1016px;
    left: 179px;
    top: 1640px;
    background: #0e6fa0;
    opacity: 0.2;
    filter: blur(125px);
    @media (max-width: 1024px) {
      width: 0px;
      height: 0px;
    }
  }

  // .style-private-1 {
  //   background: url("/geschaftskunden1.jpg"),
  //     linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
  //   background-position: center;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-blend-mode: overlay;
  //   width: 100%;
  //   height: 100%;
  // }
  // .style-private-2 {
  //   background: url("/geschaftskunden2.jpg"),
  //     linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
  //   background-position: center;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-blend-mode: overlay;
  //   width: 100%;
  //   height: 100%;
  // }
  // .style-private-3 {
  //   background: url("/geschaftskunden3.jpg"),
  //     linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
  //   background-position: center;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-blend-mode: overlay;
  //   width: 100%;
  //   height: 100%;
  // }
  // .style-private-4 {
  //   background: url("/geschaftskunden4.jpg"),
  //     linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
  //   background-position: center;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-blend-mode: overlay;
  //   width: 100%;
  //   height: 100%;
  // }
  // .style-private-5 {
  //   background: url("/geschaftskunden5.jpg"),
  //     linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.2));
  //   background-position: center;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-blend-mode: overlay;
  //   width: 100%;
  //   height: 100%;
  // }
`;

function Section({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {children}
      </span>
    </section>
  );
}

export default function geschäftskunden() {
  return (
    <GeschäftskundenWrapper>
      <div className="bg-color"></div>
      <div className="bg-color1"></div>
      <div className="bg-color2"></div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Section>
            <h1 class="text-4xl sm:text-7xl py-32">
              GESCHÄFTSKUNDEN
              <Divider class="bg-white w-[80%] mt-5 h-1 flex align-center justify-center m-auto " />
            </h1>
          </Section>
        </Grid>
      </Grid>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} className="style-private-5  ">
            <Section>
              <div class="card   ">
                <h1 class="max-md:text-[20px]">FINANZBUCHHALTUNG</h1>
                <p>
                  Die Finanzbuchhaltung bringt wie alle anderen
                  Finanzangelegenheiten einen Haufen Arbeit mit sich. Um Sie zu
                  entlasten, bieten wir Ihnen unseren erstklassigen Service zur
                  Finanzbuchhaltung an. Hierbei ist uns eine transparente und
                  kommunikative Zusammenarbeit mit Ihnen wichtig. Die
                  Finanzbuchhaltung beinhaltet je nach Art und Grösse eines
                  Unternehmens zahlreiche verschiedene Leistungen und
                  Sonderleistungen, mit denen wir und hervorragend auskennen.
                  Finanzbuchhaltung individuell auf Sie und die Bedürfnisse
                  Ihres Unternehmens zugeschnitten und personalisiert ist
                  heutzutage nicht mehr selbstverständlich, für uns jedoch ein
                  absolutes Muss. Eine langfristige Zusammenarbeit mit Ihnen auf
                  vertrauensvoller Basis und zum Wohl Ihrer Finanzen ist unser
                  Ziel. Selbstverständlich halten wir Sie stets auf dem
                  Laufenden, was Ihre Buchhaltung betrifft.
                </p>
              </div>
            </Section>
          </Grid>
          <Grid item xs={12} md={12}>
            <Section>
              <div class="card  ">
                <h1 class="max-sm:text-[19px]">STEUERBERATUNG</h1>
                <p>
                  Die Steuerberatung im Allgemeinen gehört zu unserer breit
                  gefächerten Angebotspalette. Zahlreiche
                  betriebswirtschaftliche Themen, der weitere Unternehmensweg
                  und natürlich auch Ihre private Situation beziehen wir gerne
                  in unsere Steuerberatung mit ein. Eine Steuerberatung bedeutet
                  für uns, Ihnen den bestmöglichen Service zu bieten. Für uns
                  ist jedes Mandat aussergewöhnlich und der Mensch hinter den
                  vielen Unterlagen und Zahlen spielt eine wichtige Rolle dabei.
                  Sie sind nicht nur ein weiteres Mandat, irgendeine Nummer oder
                  Abrechnung, sondern Sie als Kunde liegen uns persönlich am
                  Herzen. Durch dieses Alleinstellungsmerkmal heben wir uns von
                  den Mitbewerbern ab und freuen uns, Sie persönlich
                  kennenlernen zu dürfen. Fragen rund um die Steuerberatung
                  beantworten wir Ihnen selbstverständlich gerne.
                </p>
              </div>
            </Section>
          </Grid>
          <Grid item xs={12} md={12}>
            <Section>
              <div class="card ">
                <h1 class="max-md:text-[20px]">Mehrwertsteuer - MWST</h1>
                <p>
                  Die Mehrwertsteuer in der Schweiz ist insbesondere für
                  Unternehmen sehr komplex geregelt, dies führt immer wieder zu
                  fehlerhaften Steuerunterlagen und oft zu hohen
                  Nachforderungen. Dies muss nicht sein, denn mit der
                  Mehrwertsteuer für Unternehmen und deren Hindernissen für
                  Unternehmer kennen wir uns zum Glück aus. Lassen Sie sich
                  gerne von uns beraten oder übergeben Sie uns die steuerlichen
                  Angelegenheiten gleich ganz. Auf diese Weise müssen Sie sich
                  nicht mit komplexen Steuerangelegenheiten auseinandersetzen
                  und Fehler können von vorneherein vermieden werden. Auch
                  komplexe Fragestellungen zur Mehrwertsteuer in der Schweiz
                  beantworten wir Ihnen mit Vergnügen, lassen Sie sich jetzt von
                  uns beraten.
                </p>
              </div>
            </Section>
          </Grid>
          <Grid item xs={12} md={12}>
            <Section>
              <div class="card ">
                <h1 class="max-md:text-[20px]">JAHRESABSCHLUSS</h1>
                <p>
                  Steuerliche und betriebswirtschaftliche Unternehmerpflichten
                  sind nicht immer einfach zu überblicken. Hinsichtlich der
                  wachsenden Komplexität der Materie und der sich häufig
                  verändernden Gesetzesklage schleichen sich gerne Fehler ein
                  und ein Jahresabschluss wird immer anstrengender und
                  zeitaufwendiger. Gerne nehmen wir Ihnen diese Bürde von den
                  Schultern. Als Unternehmer haben Sie so Zeit für die
                  wesentlichen Dinge in Ihrem Betrieb. Kümmern Sie sich in aller
                  Ruhe um Ihr Kerngeschäft und wir erledigen den Rest für Sie.
                  Zuverlässig, pünktlich und natürlich überaus korrekt erledigen
                  wir Ihre Jahresabschlüsse. Mit der gewonnenen Zeit können Sie
                  so einiges anstellen. Als selbstständiger Unternehmer ist es
                  sicherlich ein Segen für Sie, pünktlich den Betrieb verlassen
                  zu können und Zeit mit lieben Menschen zu verbringen, während
                  wir den Jahresabschluss für Sie erledigen.
                </p>
              </div>
            </Section>
          </Grid>
          <Grid item xs={12} md={12}>
            <Section>
              <div class="card">
                <h1 class="max-md:text-[20px]">
                  LOHNBUCHHALTUNG UND PERSONAL-ADMINISTRATION
                </h1>
                <p>
                  Die ständigen Gesetzesänderungen, Klauseln und Bestimmungen
                  machen es erforderlich, sich einen starken Partner für die
                  Lohnbuchhaltung ins Boot zu holen. Diesen Partner haben Sie in
                  uns gefunden, denn unsere Erfahrungswerte und dementsprechende
                  Expertise sind Ihr persönlicher Vorteil. Gehaltsabrechnungen,
                  Anträge und Formulare füllen wir für Sie aus und leiten alle
                  erforderlichen Unterlagen an die entsprechenden Behörden
                  weiter. Gewissenhaft, korrekt und vor allen Dingen pünktlich
                  stellen wir Ihnen die benötigten Gehaltsabrechnungen aus,
                  kümmern uns um die Lohnkonten, die Buchungsbelege und weitere
                  schriftliche Angelegenheiten betreffend die Lohnbuchhaltung.
                  Eine Beratung zur Personaladministration oder die Erledigung
                  selbiger gehören ebenfalls zu unseren Leistungen für Sie.
                </p>
              </div>
            </Section>
          </Grid>

          <Grid item xs={12} md={12}>
            <Section>
              <div class="card">
                <h1 class="max-md:text-[20px]">FIRMENGRÜNDUNG</h1>
                <p>
                  Die Gründung eines Unternehmens ist ein grosser Schritt, auf
                  den Sie überaus stolz sein können. Damit bei diesem wichtigen
                  Lebensereignis alles so verläuft, wie Sie es sich vorgestellt
                  haben, ist eine Beratung zur Firmengründung sinnvoll. Vom
                  Grundgedanken bis hin zur bestehenden Firma begleiten wir Sie
                  gerne. Mit unseren Erfahrungswerten und unserem Wissen rund um
                  das Firmenrecht, steuerliche Angelegenheiten und bürokratische
                  Angelegenheiten betreffend einer Gründung sind wir ein starker
                  Partner für Ihre Gründung und auch darüber hinaus. Wir sind
                  Ihnen dabei behilflich, Stolpersteinen auf dem Gründungsweg
                  aus dem Weg zu gehen und Fehler zu vermeiden. Damit Ihr
                  Unternehmen auch erfolgreich bleibt, begleiten wir Sie auch
                  nach der Gründung gerne weiter.
                </p>
              </div>
            </Section>
          </Grid>
          <Grid item xs={12} md={12}>
            <Section>
              <div class="card">
                <h1 class="max-md:text-[20px]">QUELLENSTEUER</h1>
                <p>
                  Die Quellensteuer ist insbesondere für Ausländer von
                  Bedeutung. Diese Quellensteuer ist eine Steuer, welche direkt
                  von Ihrem Arbeitslohn abgezogen wird. Selbstverständlich gibt
                  es hierbei Ausnahmen wie beispielsweise für Personen mit einem
                  Ausweis C (Niederlassungsbewilligung), oder Ehepartner von
                  Schweizer. Grenzgänger zählen ebenfalls zu den Ausnahmen.
                  Gerne erhalten Sie eine umfassende Beratung zur Quellensteuer
                  und erfahren auch, ob Sie zur steuerpflichtigen Personengruppe
                  gehören oder nicht. Da die Quellensteuer individuell kantonal
                  geregelt ist, gibt es hierbei Unterschiede, zu denen wir Sie
                  ebenfalls gerne aufklären, falls Bedarf hierzu besteht.
                  Beiträge zu Ihrer Versicherung oder zur Pensionskasse können
                  übrigens abgezogen werden.
                </p>
              </div>
            </Section>
          </Grid>
        </Grid>
      </Container>
    </GeschäftskundenWrapper>
  );
}
