import React from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import Container from "@mui/material/Container";
import { useRef } from "react";
import { useInView } from "framer-motion";

const PrivateKundenWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 120px 0px;
  h1 {
    text-align: center;
  }
  .card h1 {
    font-size: 32px;
    font-weight: bold;
    padding-bottom: 10px;
    text-align: left;
    @media(max-width: 640px){
      font-size: 25px;
    }
  }
  .card p{
    @media(max-width: 640px){
      font-size: 20px;
    }
  }
  .card {
    padding: 30px;
  }
  .bg-color {
    position: absolute;
    width: 1070px;
    height: 1016px;
    left: -179px;
    top: 400px;
    background: #41ead4;
    opacity: 0.11;
    filter: blur(125px);
    @media (max-width: 1024px) {
      width: 0px;
      height: 0px;
    }
  }
  .bg-color1 {
    position: absolute;
    width: 1070px;
    height: 1016px;
    left: 179px;
    top: 1240px;
    background: #be41ea;
    opacity: 0.11;
    filter: blur(125px);
    @media (max-width: 1024px) {
      width: 0px;
      height: 0px;
    }
  }
  .bg-color2 {
    position: absolute;
    width: 1070px;
    height: 1016px;
    left: 179px;
    top: 1640px;
    background: #0e6fa0;
    opacity: 0.2;
    filter: blur(125px);
    @media (max-width: 1024px) {
      width: 0px;
      height: 0px;
    }
  }
`;

function Section({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {children}
      </span>
    </section>
  );
}

export default function privatkunden() {
  return (
    <PrivateKundenWrapper>
      <div className="bg-color"></div>
      <div className="bg-color1"></div>
      <div className="bg-color2"></div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Section>
            <h1 class="text-4xl sm:text-7xl py-32">
              PRIVATKUNDEN
              <Divider class="bg-white w-[80%] mt-5 h-1 flex align-center justify-center m-auto " />
            </h1>
          </Section>
        </Grid>
      </Grid>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Section>
              <div class="card">
                <h1>STEUERERKLÄRUNG</h1>
                <p>
                  Steuervorteile durch eine private Steuererklärung zu erhalten,
                  das wünschen sich die meisten Menschen. In der Realität ist
                  der Mehrwert dieser privaten Steuererklärung jedoch oft
                  gering. Die Steuerersparnis ist mit viel Arbeit verbunden, ist
                  lästig und schränkt die wertvolle Freizeit erhebliche ein. Die
                  Freizeit, welche Sie mit Ihrer Familie, mit Freunden oder
                  Ihren Hobbys verbringen könnten, geht hierbei verloren. Gerne
                  kümmern wir uns für Sie um Ihre privaten Steuerangelegenheiten
                  und ersparen Ihnen die lästige Arbeit. Mit vollem Einsatz
                  arbeiten wir an Ihren Unterlagen. Wir sorgen dafür, dass sich
                  keine Fehler einschleichen und sämtliche Papiere korrekt
                  ausgefüllt sind. Profitieren auch Sie von unserem zügigen,
                  korrekten und günstigen Steuerservice für Privatpersonen
                </p>
              </div>
            </Section>
          </Grid>
          <Grid item xs={12} md={12}>
            <Section>
              <div class="card">
                <h1>BEWERBUNGEN (ALK) MENTOR & COACHING</h1>
                <p>
                  Eine Bewerbung ist heutzutage nicht mehr so einfach, wie zu
                  früheren Zeiten. Einfach persönlich bei der von Ihnen
                  gewünschten Arbeitsstelle vorbeischauen und nachfragen, das
                  gehört der Vergangenheit an. Eine Bewerbung muss
                  aussagekräftig sein, sich abheben, die Form muss korrekt sein
                  und es gibt noch so viel mehr zu beachten. Gerne bieten wir
                  Ihnen ein umfassendes Coaching an, damit auch Ihre Bewerbung
                  ein voller Erfolg wird. Korrektes Verhalten, Wissen und vieles
                  mehr können wir Ihnen vermitteln. Vertrauen Sie auch unsere
                  ausgezeichnete Expertise und lassen Sie sich vollumfänglich
                  oder teilweise coachen. So gelangen Sie schneller an Ihren
                  Traumberuf.
                </p>
              </div>
            </Section>
          </Grid>
          <Grid item xs={12} md={12}>
            <Section>
              <div class="card">
                <h1>UNTERSTÜTZUNG UND BERATUNG BEIM BEHÖRDENGANG</h1>
                <p>
                  Behördengänge sind nicht jedermanns Sache. Schnell wächst
                  einem alles über den Kopf, man weiss nicht, wo man anfangen
                  soll und welche Behörde überhaupt die richtige Anlaufstelle
                  für das Anliegen ist. Wir bieten tatkräftige Unterstützung bei
                  Behördengängen jeglicher Art an. <br />
                  <br />
                  <span class="underline">
                    VORPENSIONIERUNG / UNTERTSTÜTZUNG
                  </span>
                  <br />
                  <br />
                  IM ALTER Eine Vorpensionierung ist beispielsweise ist einigem
                  Verwaltungsaufwand verbunden. Damit alles richtig
                  vonstattengeht, bieten wir Ihnen unsere Unterstützung rund um
                  die Vorpensionierung an. Gerne beantworten wir Ihnen sämtliche
                  aufkommende Fragen zu dieser Thematik. Finanzberatung und
                  weiter Beratung rund um die Vorpensionierung erhalten Sie
                  ebenfalls bei uns. <br />
                  <br />
                  <span class="underline">MENTOR COACHING </span> <br />
                  <br /> Wir begleiten Sie zu Ihrem Termin, machen uns
                  gegebenenfalls Notizen und sorgen dafür, dass alles seine
                  Richtigkeit hat. Auf diese Art kann nichts vergessen werden
                  und bei Bedarf erklären wir Ihnen noch einmal, was bei diesem
                  Termin besprochen oder beantragt wurde, worum es sich genau
                  handelte und noch mehr. So schliessen wir Verständnisprobleme
                  aus und Sie sind stets auf der sicheren Seite.
                </p>
              </div>
            </Section>
          </Grid>
          <Grid item xs={12} md={12}>
            <Section>
              <div class="card">
                <h1>PRÄMIENVERBILIGUNGEN</h1>
                <p>
                  Haben Sie gewusst, dass es eine sogenannte Prämienverbilligung
                  gibt? Diese kann auf die Beiträge zu der in der Schweiz
                  obligatorischen Krankenversicherung angewendet werden.
                  Menschen, die sich in wirtschaftlich schwierigen oder
                  bescheidenen Situationen befinden, können sich diese
                  Prämienverbilligungen als Entlastung nutzen. Die Regelungen
                  hierzu werden kantonal individuell festgesetzt, sind Sie sich
                  unsicher, ob Sie eine Prämienverbilligung beantragen können,
                  sprechen Sie uns gerne an. Wir beraten Sie umfänglich zum
                  Thema Prämienverbilligung und helfen Ihnen, diese zu erwirken,
                  sofern Sie anspruchsberechtigt sind. Besondere Regelungen für
                  Kinder, Jugendliche oder junge Menschen in der Ausbildung oder
                  bei der Veränderung Ihres Einkommens sind uns ebenso bekannt.
                </p>
              </div>
            </Section>
          </Grid>
          <Grid item xs={12} md={12}>
            <Section>
              <div class="card">
                <h1>PRIVATE BUDGETPLANNUNG / SCHULDENMANAGEMENT</h1>
                <p>
                  Schuldenmanagement und die Planung des privaten Budgets sind
                  nicht immer einfach zu bewältigen. Die Bewältigung des
                  Schuldenmanagements bieten wir diskret und auf Augenhöhe an.
                  Wir verhelfen Ihnen zu einem Weg, die Schulden loszuwerden und
                  analysieren die Verschuldung genau. Bei behördlichen
                  Angelegenheiten, bürokratischen Aufgaben und vielem mehr
                  stehen wir Ihnen zuverlässig und mit viel Knowhow zur Seite.
                  Wie auch immer Ihre Lebenslage gerade aussieht, finden wir
                  gemeinsam einen zielorientierten Lösungsweg. Ob Sie
                  geschieden, verwitwet, verheiratet oder alleinstehend sind,
                  spielt hierbei keine Rolle. Neben der Beratung rund um das
                  Schuldenmanagement entwickeln wir mit Ihnen ein Konzept zur
                  privaten Budgetplanung für Ihren weiteren Lebensweg.
                </p>
              </div>
            </Section>
          </Grid>
        </Grid>
      </Container>
    </PrivateKundenWrapper>
  );
}
