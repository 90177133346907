import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import Img from "../Image";

const AgentWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-conten: center;
  padding-bottom: 80px;
  img {
    width: 100%;
    height: 520px;
    @media (max-width: 640px) {
      width: 100%;
      height: 350px;
    }
  }
  .card {
    border: 0px;
    @media (max-width: 640px) {
      width: 100%;
      height: 100%;
    }
  }

  svg {
    color: #0e6fa0;
  }
`;

export default function Agents() {
  return (
    <AgentWrapper>
      <Container>
        <Divider class="bg-white w-full  h-1 my-32 " />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className="card p-[30px]">
              <h1 className="text-4xl leading-[50px] text-primary	">
                Sanja Marku
              </h1>
              <p text->Geschaftsinhaberin</p>
              <Img
                src="SanjaMarkaj-min.webp"
                alt="agent"
                width="100%"
                height="100%"
              />
              <div className=" pt-[30px] flex align-center justify-start">
                <a
                  href="mailto:sm@ds-finance.ch"
                  className="flex items-center "
                >
                  <MailOutlineOutlinedIcon className="path-primary " />
                  <p className="pl-10"> sm@ds-finance.ch</p>
                </a>
              </div>
              <div className=" pt-[30px] flex align-center justify-start">
                <PhoneAndroidOutlinedIcon className="fill-primary " />
                <a href="tel:+41 76 346 86 24" className="pl-10">
                  <p>+41 76 346 86 24</p>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="card p-[30px]">
              <h1 className="text-3xl leading-[50px]	text-primary">
                Drita Laskaj
              </h1>
              <p>Geschaftsinhaberin</p>
              <Img
                src="DritaLaskaj-min.webp"
                alt="agent"
                width="100%"
                height="100%"
              />
              <div className=" pt-[30px] flex align-center justify-start">
                <a
                  href="mailto:dl@ds-finance.ch"
                  className="flex items-center "
                >
                  <MailOutlineOutlinedIcon className="fill-primary " />
                  <p className="pl-10"> dl@ds-finance.ch</p>
                </a>
              </div>
              <div className=" pt-[30px] flex align-center justify-start">
                <PhoneAndroidOutlinedIcon className="fill-primary " />
                <a href="tel:+41 76 424 33 47" className="pl-10">
                  <p>+41 76 424 33 47</p>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </AgentWrapper>
  );
}
