import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import { useRef } from "react";
import { useInView } from "framer-motion";
// import Atropos from "atropos/react";
import "atropos/css";
import { Link } from "react-router-dom";

const KundenWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 120px;
  .card h1 {
    font-size: 22px;
    font-weight: bold;
    border-radius: 8px;
    transition: 0.3s;
  }
  .card p {
    font-size: 18px;
  }

  .card {
    background-color: transparent;
    border: 0px;
    transition: 0.3s;
    width: 100%;
    height: 200px;
    padding: 30px;
    @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: rgb(14, 111, 160, 0.5);
      transition: 0.3s;
      color: rgb(255 255 255);
      h1 {
      }
      b {
        color: rgb(250 219 181);
      }
    }
  }
  .height {
    height: 250px;
    @media (max-width: 1024px) {
      width: 100%;
      height: 100%;
    }
  }
`;

function Section({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {children}
      </span>
    </section>
  );
}

export default function PrivatekundenC() {
  return (
    <KundenWrapper>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Section>
              <h1 class="text-4xl sm:text-7xl py-32 max-lg:text-5xl max-sm:text-4xl">
                PRIVATKUNDEN
                <Divider class="bg-white w-[80%] mt-5 h-1 max-sm:w-full" />
              </h1>
            </Section>
          </Grid>
          <Grid item xs={12} md={5}>
            <Section>
              <Link onClick={() => window.reload()} to="/privatkunden">
                {/* <Atropos
                  shadow={false}
                  onEnter={() => console.log("Enter")}
                  onLeave={() => console.log("Leave")}
                  onRotate={(x, y) => console.log("Rotate", x, y)}
                  alwaysActive={true}
                  highlight={true}
                >
                 
                </Atropos> */}
                <div class="card">
                  <h1>STEUERERK-LÄRUNG</h1>
                  <p>
                    Steuervorteile durch eine private Steuererklärung zu
                    erhalten, das wünschen sich die meisten Menschen.{" "}
                    <b class="ease-in-out duration-200 ">mehr...</b>
                  </p>
                </div>
              </Link>
            </Section>
          </Grid>
          <Grid item xs={12} md={7}>
            <Link onClick={() => window.reload()} to="/privatkunden">
              <Section>
                {/* <Atropos
                  shadow={false}
                  onEnter={() => console.log("Enter")}
                  onLeave={() => console.log("Leave")}
                  onRotate={(x, y) => console.log("Rotate", x, y)}
                  alwaysActive={true}
                  highlight={true}
                >
                  
                </Atropos> */}
                <div class="card">
                  <h1>BEWERBUNGEN (ALK) MENTOR & COACHING</h1>
                  <p>
                    Eine Bewerbung ist heutzutage nicht mehr so einfach, wie zu
                    früheren Zeiten.
                    <b>mehr...</b>{" "}
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link onClick={() => window.reload()} to="/privatkunden">
              <Section>
                {/* <Atropos
                  shadow={false}
                  onEnter={() => console.log("Enter")}
                  onLeave={() => console.log("Leave")}
                  onRotate={(x, y) => console.log("Rotate", x, y)}
                  alwaysActive={true}
                  highlight={true}
                >
                  
                </Atropos> */}
                <div class="card">
                  <h1>UNTERSTÜTZUNG UND BERATUNG BEIM BEHÖRDENGANG</h1>
                  <p>
                    Behördengänge sind nicht jedermanns Sache. <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link onClick={() => window.reload()} to="/privatkunden">
              <Section>
                {/* <Atropos
                  shadow={false}
                  onEnter={() => console.log("Enter")}
                  onLeave={() => console.log("Leave")}
                  onRotate={(x, y) => console.log("Rotate", x, y)}
                  alwaysActive={true}
                  highlight={true}
                >
                  
                </Atropos> */}
                <div class="card">
                  <h1>PRÄMIENVERBILIGUNGEN</h1>
                  <p>
                    Haben Sie gewusst, dass es eine sogenannte
                    Prämienverbilligung gibt? Diese kann auf die Beiträge zu der
                    in der Schweiz obligatorischen Krankenversicherung
                    angewendet werden.
                    <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>

          <Grid item xs={12} md={7}>
            <Link onClick={() => window.reload()} to="/privatkunden">
              <Section>
                {/* <Atropos
                  shadow={false}
                  onEnter={() => console.log("Enter")}
                  onLeave={() => console.log("Leave")}
                  onRotate={(x, y) => console.log("Rotate", x, y)}
                  alwaysActive={true}
                  highlight={true}
                >
                  
                </Atropos> */}
                <div class="card">
                  <h1>PRIVATE BUDGETPLANNUNG / SCHULDENMANAGEMENT</h1>
                  <p>
                    Schuldenmanagement und die Planung des privaten Budgets sind
                    nicht immer einfach zu bewältigen.
                    <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <h1 class="text-3xl sm:text-6xl py-32 ">
              GESCHÄFTSKUNDEN
              <Divider class="bg-white w-[80%] mt-5 h-1 max-sm:w-full" />
            </h1>
          </Grid>
          <Grid item xs={12} md={7}>
            <Link onClick={() => window.reload()} to="/geschäftskunden">
              <Section>
                {/* <Atropos
                  shadow={false}
                  onEnter={() => console.log("Enter")}
                  onLeave={() => console.log("Leave")}
                  onRotate={(x, y) => console.log("Rotate", x, y)}
                  alwaysActive={true}
                  highlight={true}
                >
                  
                </Atropos> */}
                <div class="card">
                  <h1>FINANZBUCHHALTUNG</h1>
                  <p>
                    Die Finanzbuchhaltung bringt wie alle anderen
                    Finanzangelegenheiten einen Haufen Arbeit mit sich.
                    <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
          <Grid item xs={12} md={5}>
            <Link onClick={() => window.reload()} to="/geschäftskunden">
              <Section>
                {/* <Atropos
                  shadow={false}
                  onEnter={() => console.log("Enter")}
                  onLeave={() => console.log("Leave")}
                  onRotate={(x, y) => console.log("Rotate", x, y)}
                  alwaysActive={true}
                  highlight={true}
                >
                 
                </Atropos> */}
                <div class="card">
                  <h1>STEUERBERATUNG</h1>
                  <p>
                    Die Steuerberatung im Allgemeinen gehört zu unserer breit
                    gefächerten Angebotspalette.
                    <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
          <Grid item xs={12} md={5}>
            <Link onClick={() => window.reload()} to="/geschäftskunden">
              <Section>
                {/* <Atropos
                  shadow={false}
                  onEnter={() => console.log("Enter")}
                  onLeave={() => console.log("Leave")}
                  onRotate={(x, y) => console.log("Rotate", x, y)}
                  alwaysActive={true}
                  highlight={true}
                >
                  
                </Atropos> */}
                <div class="card height">
                  <h1>Mehrwertsteuer - MWST</h1>
                  <p>
                    Die Mehrwertsteuer in der Schweiz ist insbesondere für
                    Unternehmen sehr komplex geregelt, dies führt immer wieder
                    zu fehlerhaften Steuerunterlagen und oft zu hohen -
                    Nachforderungen.
                    <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
          <Grid item xs={12} md={7}>
            <Link onClick={() => window.reload()} to="/geschäftskunden">
              <Section>
                {/* <Atropos
                  shadow={false}
                  onEnter={() => console.log("Enter")}
                  onLeave={() => console.log("Leave")}
                  onRotate={(x, y) => console.log("Rotate", x, y)}
                  alwaysActive={true}
                  highlight={true}
                >
                 
                </Atropos> */}
                <div class="card height ">
                  <h1>JAHRESABSCHLUSS</h1>
                  <p>
                    Steuerliche und betriebswirtschaftliche Unternehmerpflichten
                    sind nicht immer einfach zu überblicken.
                    <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link onClick={() => window.reload()} to="/geschäftskunden">
              <Section>
                {/* <Atropos
                  shadow={false}
                  onEnter={() => console.log("Enter")}
                  onLeave={() => console.log("Leave")}
                  onRotate={(x, y) => console.log("Rotate", x, y)}
                  alwaysActive={true}
                  highlight={true}
                >
                 
                </Atropos> */}
                <div class="card height">
                  <h1>LOHNBUCHHALTUNG UND PERSONAL-ADMINISTRATION</h1>
                  <p>
                    Die ständigen Gesetzesänderungen, Klauseln und Bestimmungen
                    machen es erforderlich, sich einen starken Partner für die
                    Lohnbuchhaltung ins Boot zu holen.
                    <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}>
            <Link onClick={() => window.reload()} to="/geschäftskunden">
              <Section>
                <div class="card height">
                  <h1>FIRMENGRÜNDUNG</h1>
                  <p>
                    Die Gründung eines Unternehmens ist ein grosser Schritt, auf
                    den Sie überaus stolz sein können.
                    <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={8}>
            <Link onClick={() => window.reload()} to="/geschäftskunden">
              <Section>
                <div class="card">
                  <h1>SOZIALVERSICHERUNGEN </h1>
                  <p>
                    Rund um Sozialversicherungen für Sie uns für Ihre
                    Mitarbeiter gibt es selbstverständlich ebenfalls einen
                    hervorragenden Service von unserer Seite zu erwarten.
                    <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
          <Grid item xs={12} md={12}>
            <Link onClick={() => window.reload()} to="/geschäftskunden">
              <Section>
                <div class="card">
                  <h1>QUELLENSTEUER </h1>
                  <p>
                    Die Quellensteuer ist insbesondere für Ausländer von
                    Bedeutung. Diese Quellensteuer ist eine Steuer, welche
                    direkt von Ihrem Arbeitslohn abgezogen wird.
                    Selbstverständlich gibt es hierbei Ausnahmen wie
                    beispielsweise für Personen mit einem Ausweis C
                    (Niederlassungsbewilligung), oder Ehepartner von Schweizer.
                    
                    <b>mehr...</b>
                  </p>
                </div>
              </Section>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </KundenWrapper>
  );
}
