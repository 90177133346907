import React from "react";
import { Link } from "react-router-dom";
import "./nav.css";

function NavBar() {
  return (
    <div>
      <input
        className="menu-icon"
        type="checkbox"
        id="menu-icon"
        name="menu-icon"
      />
      <label htmlFor="menu-icon"></label>
      <div className="nav">
        <ul>
          <li>
            <Link onClick={() => window.reload()} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={() => window.reload()} to="/über-uns">
              ÜBER UNS
            </Link>
          </li>
          <li>
            <Link onClick={() => window.reload()} to="/privatkunden">
              Privatkunden
            </Link>
          </li>
          <li>
            <Link onClick={() => window.reload()} to="/geschäftskunden">
              Geschäftskunden
            </Link>
          </li>
          <li>
            <Link onClick={() => window.reload()} to="/kontakt">
              KONTAKT
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NavBar;
