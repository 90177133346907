import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

export default function impresum() {
  return (
    <div className="py-[180px]">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            Ds-finance <br />
            <br />
            Meierhöflistrasse 3, 6020, Emmenbrücke
            <br />
            <br />
            Telefon: +41 76 346 86 24
            <br />
            Mail: info@ds-finance.ch
            <br />
            <br />
            Website und Hosting: W1A GmbH
            <br />
            <br />
            Eintrag im Handelsregister
            <br />
            <br />
            Mehrwertsteuernummer MWSt CHE-438.210.482. <br />
            <br />
            COPYRIGHT <br />
            <br />
            Alle Nutzungsrechte liegen bei den Autoren. Der Inhalt der Website
            ist urheberrechtlich geschützt. Nachdruck, Aufnahme in
            Online-Dienste, Internet und Vervielfältigung auf Datenträger wie
            CD-ROM, DVD-ROM usw. dürfen, auch auszugsweise, nur nach vorheriger,
            schriftlicher Zustimmung erfolgen.
            <br />
            <br />
            HAFTUNG FÜR INHALTE
            <br />
            <br />
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
            die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können
            wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
            gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach § 8 bis 10 TMG sind wir
            als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
            <br />
            <br />
            HAFTUNG FÜR LINKS <br />
            <br />
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
            <br />
            <br />
            URHEBERRECHT <br />
            <br />
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem schweizer Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
            <br />
            <br />
            Datenschutz <br />
            <br />
            Mit dieser Datenschutzerklärung informieren wir über die Bearbeitung
            von Personendaten im Zusammenhang mit unserem Angebot.
            <br />
            <br />
            Unser Angebot unterliegt dem schweizerischen Datenschutzrecht. Wir
            bearbeiten Personendaten im Einklang mit dem schweizerischen
            Datenschutzrecht wie insbesondere dem Datenschutzgesetz (DSG). Die
            EU anerkennt, dass das schweizerische Datenschutzrecht einen
            angemessenen Datenschutz gewährleistet.
            <br />
            <br />
            1. Bearbeitung von Personendaten
            <br />
            <br />
            1.1 Personendaten sind alle Angaben, die sich auf eine bestimmte
            oder bestimmbare Person beziehen. Eine betroffene Person ist eine
            Person, über die Personendaten bearbeitet
            werden. Bearbeiten umfasst jeden Umgang mit Personendaten,
            unabhängig von den angewandten Mitteln und Verfahren, insbesondere
            das Aufbewahren, Bekanntgeben, Beschaffen, Löschen, Speichern,
            Verändern, Vernichten und Verwenden von Personendaten.
            <br />
            <br />
            1.2 Wir bearbeiten jene <br />
            Personendaten, die erforderlich sind, um unser Angebot effektiv und
            nutzerfreundlich sowie dauerhaft, sicher und zuverlässig
            bereitstellen zu können.
            <br />
            <br />
            1.3 Wir bearbeiten <br />
            Personendaten grundsätzlich nur nach Einwilligung der betroffenen
            Person, es sei denn, die Bearbeitung ist aus anderen Gründen
            zulässig, beispielsweise zur Erfüllung eines Vertrages mit der
            betroffenen Personen und für entsprechende vorvertragliche
            Massnahmen, um unsere überwiegenden Interessen zu wahren, weil die
            Bearbeitung aus den Umständen ersichtlich ist oder nach vorgängiger
            Information. In diesem Rahmen bearbeiten wir insbesondere Angaben,
            die eine betroffene Person bei der Kontaktaufnahme – beispielsweise
            per Briefpost, E-Mail, Kontaktformular, Telefon oder Social Media –
            selbst übermittelt.
            <br />
            <br />
            1.4 Wir bearbeiten <br />
            Personendaten für jene Dauer, die für den jeweiligen Zweck oder die
            jeweiligen Zwecke erforderlich ist. Bei länger dauernden
            Aufbewahrungspflichten aufgrund von  gesetzlichen und sonstigen
            Pflichten, denen wir unterliegen, schränken wir die Bearbeitung
            entsprechend ein.
            <br />
            <br />
            1.5 Wir können Personendaten durch Dritte – auch im Ausland –
            bearbeiten lassen. Solche Auftragsbearbeiter bearbeiten
            Personendaten in unserem Auftrag. Wir können Personendaten weiter
            mit Hilfe von Dritten – auch im Ausland – bearbeiten. Wir stellen
            jeweils sicher, dass solche Dritte einen angemessenen Datenschutz
            gewährleisten.
            <br />
            <br />
            1.6 Wir sind auf Social Media-Plattformen und anderen
            Online-Plattformen präsent, um mit interessierten Personen
            kommunizieren und über unser Angebot informieren zu können. Es
            gelten  jeweils die Allgemeinen Geschäftsbedingungen (AGB),
            Datenschutzerklärungen und sonstigen Bestimmungen der jeweiligen
            Betreiberinnen und Betreiber.
            <br />
            <br />
            2. Cookies und Logdateien
            <br />
            <br />
            2.1 Für jeden Besuch auf unserer Website erfassen wir folgende
            Daten, sofern diese von Ihrem Browser an unsere Server-Infrastruktur
            übermittelt werden, und speichern diese Daten in Logdateien:
            <br />
            <br />
            Datum und Zeit einschliesslich Zeitzone
            <br />
            <br />
            Internet Protocol (IP)-Adresse
            <br />
            <br />
            Zugriffsstatus (HTTP-Statuscode)
            <br />
            <br />
            Betriebssystem einschliesslich Benutzeroberfläche und Version
            <br />
            <br />
            Browser einschliesslich Sprache und Version
            <br />
            <br />
            aufgerufene einzelne Seite und übertragene Datenmenge
            <br />
            <br />
            zuletzt aufgerufene Webseite (Referer)
            <br />
            <br />
            Solche Daten können Personendaten darstellen. Die Daten sind
            erforderlich, um unser Angebot dauerhaft, sicher und zuverlässig
            bereitstellen sowie um die Datensicherheit und damit insbesondere
            den Schutz von Personendaten sicherstellen zu können – auch durch
            Dritte oder mit Hilfe von Dritten.
            <br />
            <br />
            2.2 Wir verwenden Cookies auf unserer Website. Bei Cookies – auch
            von Dritten, deren Dienste wir nutzen (Third-Party Cookies) –
            handelt es sich um Textdateien, die in Ihrem Browser gespeichert
            werden. Cookies können beim Besuch unserer Website in Ihrem Browser
            gespeichert werden. Cookies ermöglichen insbesondere, Ihren Browser
            beim nächsten Besuch unserer Website wiederzuerkennen. Cookies
            können keine Programme ausführen oder Schadsoftware wie Trojaner und
            Viren übertragen. Cookies sind erforderlich, um unser Angebot
            einschliesslich unserer Website effektiv und nutzerfreundlich sowie
            dauerhaft, sicher und zuverlässig bereitstellen zu können,
            insbesondere durch die Analyse der Nutzung im Hinblick auf
            Fehlerbehebung und Verbesserungen.
            <br />
            <br />
            Sie können Cookies in Ihren Browser-Einstellungen jederzeit ganz
            oder teilweise deaktivieren sowie löschen. Ohne Cookies steht unser
            Angebot allenfalls nicht mehr in vollem Umfang zur Verfügung. Wir
            informieren – sofern und soweit erforderlich – direkt über die
            Verwendung von Cookies oder ersuchen Sie direkt um die Einwilligung
            von Cookies.
            <br />
            <br />
            3. Organisatorische und technische Massnahmen
            <br />
            <br />
            3.1 Wir treffen angemessene und geeignete organisatorische und
            technische Massnahmen um den Datenschutz und die Datensicherheit zu
            gewährleisten.
            <br />
            <br />
            3.2 Der Zugriff auf unser Angebot erfolgt mittels
            Transportverschlüsselung (SSL / TLS).
            <br />
            <br />
            3.3 Die Bearbeitung von Personendaten im Internet kann trotz
            angemessenen und geeigneten organisatorischen und technischen
            Massnahmen immer Sicherheitslücken aufweisen. Wir können deshalb
            keine absolute Datensicherheit gewährleisten.
            <br />
            <br />
            3.4 Der Zugriff auf unser Angebot unterliegt – wie grundsätzlich
            jede Internet-Nutzung – der anlasslosen und verdachtsunabhängigen
            Massenüberwachung sowie sonstigen Überwachung durch
            Sicherheitsbehörden in der Schweiz, in der EU, in den Vereinigten
            Staaten von Amerika (USA) und in anderen Staaten. Wir können keinen
            direkten Einfluss auf die entsprechende Bearbeitung von
            Personendaten durch Geheimdienste, Polizeistellen und andere
            Sicherheitsbehörden nehmen.
            <br />
            <br />
            4. Benachrichtigungen und Newsletter
            <br />
            <br />
            4.1 Wir können Benachrichtigungen und Newsletter per E-Mail sowie
            über andere Kommunikationskanäle versenden. Sofern und soweit der
            Versand nicht erforderlich ist, um einen Vertrag mit der betroffenen
            Person zu erfüllen oder um unsere überwiegenden Interessen zu
            wahren, müssen Sie in die Verwendung Ihrer E-Mail-Adresse und Ihrer
            sonstigen Kontaktadressen ausdrücklich einwilligen, damit kein
            Missbrauch durch unberechtigte Dritte erfolgen kann («Double
            Opt-in»). Wir können Benachrichtigungen und Newsletter durch Dritte
            versenden lassen oder mit Hilfe von Dritten versenden.
            <br />
            <br />
            4.2 Benachrichtigungen und Newsletter können Zählpixel oder Weblinks
            enthalten, die erfassen, ob eine einzelne Benachrichtigung oder ein
            einzelner Newsletter geöffnet wurde und welche Weblinks dabei
            angeklickt wurden. Solche Zählpixel und Weblinks erfassen die
            Nutzung von  Benachrichtigungen und Newslettern. Wir benötigen diese
            statistische Erfassung der Nutzung einschliesslich Erfolgs- und
            Reichweitenmessung, um Benachrichtigungen und Newsletter aufgrund
            der Lesegewohnheiten der Empfängerinnen und Empfänger effektiv und
            nutzerfreundlich sowie dauerhaft, sicher und zuverlässig anbieten zu
            können.
            <br />
            <br />
            4.3 Sie können sich jederzeit von Benachrichtigungen sowie
            Newslettern abmelden und dadurch insbesondere der erwähnten
            Erfassung der Nutzung widersprechen.
            <br />
            <br />
            5. Dienste von Dritten
            <br />
            <br />
            5.1 Wir verwenden Dienste von Dritten – auch im Ausland
            einschliesslich den Vereinigten Staaten von Amerika (USA) –, um
            unser Angebot effektiv und nutzerfreundlich sowie dauerhaft, sicher
            und zuverlässig bereitstellen zu können. Solche Dienste – unter
            anderem Hosting-Dienstleister – benötigen Ihre Internet Protocol
            (IP)-Adresse, da die entsprechenden Inhalte ansonsten nicht
            ausgeliefert werden können. Solche Dienste können – unter anderem
            mit Cookies, Logdateien und Zählpixel – auch weitere Daten im
            Zusammenhang mit unserem Angebot und in Verbindung mit Informationen
            aus anderen Quellen für ihre kommerzielle Kommunikation und für ihre
            statistischen Zwecke bearbeiten.
            <br />
            <br />
            5.2 Wir verwenden Google Fonts, um Schriften in unsere Website
            einbetten können. Google Fonts ist ein Dienst der amerikanischen
            Google LLC. Wir benötigen diesen Dienst, um unser Angebot
            einschliesslich unserer Website effektiv und nutzerfreundlich sowie
            dauerhaft, sicher und zuverlässig bereitstellen zu können.
            <br />
            <br />
            Google unterliegt dem schweizerisch-amerikanischen Privacy Shield,
            womit sich Google verpflichtet, einen angemessenen Datenschutz zu
            gewährleisten. Google hat insbesondere folgende Informationen zu
            Art, Umfang und Zweck der Datenbearbeitung im Zusammenhang mit
            Google Fonts veröffentlicht: Datenschutz bei Google
            Fonts, Datenschutzerklärung und Nutzungsbedingungen, Eintrag in der
            Privacy Shield-Liste.
            <br />
            <br />
            5.3 Wir verwenden Google Maps, um Karten in unsere Website einbetten
            zu können. Google Maps ist ein Dienst der amerikanischen Google LLC.
            Wir benötigen diesen Dienst, um unser Angebot einschliesslich
            unserer Website effektiv und nutzerfreundlich sowie dauerhaft,
            sicher und zuverlässig bereitstellen zu können.
            <br />
            <br />
            Google unterliegt dem schweizerisch-amerikanischen Privacy Shield,
            womit sich Google verpflichtet, einen angemessenen Datenschutz zu
            gewährleisten. Google hat insbesondere folgende Informationen zu
            Art, Umfang und Zweck der Datenbearbeitung im Zusammenhang mit
            Google Maps veröffentlicht: Datenschutz bei Google-Produkten
            einschliesslich Google Maps, Datenschutzerklärung und
            Nutzungsbedingungen, Eintrag in der Privacy Shield-Liste.
            <br />
            <br />
            5.4 Wir verwenden YouTube, um Videos in unsere Website einbetten zu
            können. YouTube ist ein Dienst der amerikanischen Google LLC. Wir
            benötigen diesen Dienst, um unser Angebot einschliesslich unserer
            Website effektiv und nutzerfreundlich sowie dauerhaft, sicher und
            zuverlässig bereitstellen zu können.
            <br />
            <br />
            Google unterliegt dem schweizerisch-amerikanischen Privacy Shield,
            womit sich Google verpflichtet, einen angemessenen Datenschutz zu
            gewährleisten. Google hat insbesondere folgende Informationen zu
            Art, Umfang und Zweck der Datenbearbeitung im Zusammenhang mit
            YouTube veröffentlicht: Datenschutz bei Google-Produkten
            einschliesslich YouTube, Datenschutzerklärung und
            Nutzungsbedingungen, Eintrag in der Privacy Shield-Liste.
            <br />
            <br />
            5.5 Wir binden die Funktion zur Erkennung von Bots, z.B. bei
            Eingaben in Onlineformularen “ReCaptcha” des Anbieters Google LLC,
            1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein.
            Datenschutzerklärung: https://www.google.com/policies/privacy/,
            Opt-Out: https://adssettings.google.com/authenticated.
            <br />
            <br />
            6. Rechte von betroffenen Personen
            <br />
            <br />
            6.1 Betroffene Personen, deren Personendaten wir bearbeiten,
            verfügen über die Rechte gemäss schweizerischem Datenschutzrecht.
            Dazu zählen das Recht auf Auskunft sowie das Recht auf Berichtigung,
            Löschung oder Sperrung der bearbeiteten Personendaten.
            <br />
            <br />
            6.2 Aufsichtsbehörde für den Datenschutz in der Schweiz ist
            der Eidgenössische Datenschutz- und
            Öffentlichkeitsbeauftragte (EDÖB).
            <br />
            <br />
            7. Kontaktadressen und Verantwortung
            <br />
            <br />
            Anfragen von Aufsichtsbehörden und betroffenen Personen erreichen
            uns in der Regel per E-Mail, sind aber auch per Briefpost möglich:
            <br />
            <br />
            ds-finance <br />
            <br />
            Meierhöflistrasse 3, 6020, Emmenbrücke
            <br />
            <br />
            Telefon: +41 76 346 86 24
            <br />
            Mail: info@ds-finance.ch
            <br />
            <br />
            Bei Fragen rund um den Datenschutz im Zusammenhang mit unserem
            Angebot wenden Sie sich bitte an Sanja Marku und Drita Laskaj.
            <br />
            <br />
            8. Schlussbestimmungen
            <br />
            <br />
            Wir können unsere Datenschutzerklärung jederzeit ändern. Wir
            informieren betroffene Personen in geeigneter Form auf unserer
            Website über solche Änderungen.
            <br />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
