import React from "react";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Img from "../Image"
const UberWrapper = styled.div`
  background: url("/UberUns-min.webp"),
    linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55));
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  width: 100%;
  height: 100%;
  .card {
    padding: 30px;
    border: 0px;
    @media (max-width: 640px) {
      padding: 10px;
    }
  }
  .card h1 {
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
    @media (max-width: 640px) {
      font-size: 3.5rem;
      line-height: 1;
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
  }
`;

export default function UberUns() {
  return (
    <>
      <UberWrapper>
        <Grid
          container
          spacing={2}
          class="md:flex md:align-center md:justify-between  py-44 px-4 "
        >
          <Grid item xs={12} md={7} className="max-lg:hidden"></Grid>

          <Grid item xs={12} md={5} className="card">
            <h1 class="text-4xl sm:text-7xl py-18">
              Über Uns
              <Divider class="bg-white w-[50%] mt-5 h-1" />
            </h1>

            <p class=" font-semibold">
              Zwei Frauen, ein Ziel: Unser Unternehmen DS Finance GmbH entstand
              aus der Idee heraus, einen freundlichen, korrekten und besonderen
              Finanzservice auf Augenhöhe bieten zu wollen. Für Privatkunden und
              auch für Firmenkunden entstand so ein umfassender Leistungskatalog
              bezüglich finanzieller Angelegenheiten. Wir kennen uns mit dem
              Schweizer System hervorragend aus und haben in jeder von uns
              angebotenen Leistung bereits Erfahrungen sammeln können. Diese
              Erfahrungen stammen aus dem Leben und aus unserem jeweiligen
              beruflichen Werdegang, den wir durchlaufen haben.
            </p>
            <Link Link onClick={() => window.reload()} to="/über-uns">
              <Button
                variant="outlined"
                style={{
                  marginTop: "24px",
                  padding: "12px 32px",
                  border: "2px solid #0e6fa0",
                  color: "#fff",
                  zIndex: "0",
                  backgroundColor: "#222831",
                }}
              >
                über-uns
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={1} className="max-lg:hidden"></Grid>
        </Grid>

        <Grid item xs={12} md={12} class="w-full ">
          <div class="items-center justify-center m-auto w-full h-full ">
            <div class="flex align-center justify-center">
              <p>Wir Arbeiten mit:</p>
            </div>
            <div class="flex align-center justify-center">
              <KeyboardArrowDownIcon class="w-10 h-10 fill-white" />
            </div>
          </div>
          <div class="grid grid-cols-3  h-full w-full m-auto  items-center justify-center p-8 ">
            <div class="lg:w-[50%] p-1 h-full flex align-center justify-center m-auto ">
              <Img
                src="/Bexio-min.webp"
                alt="Bexios"
                width="100%"
                height="100%"
              />
            </div>
            <div class="lg:w-[50%] p-1 h-full flex align-center justify-center m-auto  ">
              <Img
                src="/Banana-min.webp"
                alt="Domuso"
                width="100%"
                height="100%"
              />
            </div>
            <div class="lg:w-[50%] p-1 h-full flex align-center justify-center m-auto    ">
              <Img
                src="/optiwork-min.webp"
                alt="Banana"
                width="100%"
                height="100%"
              />
            </div>{" "}
          </div>
        </Grid>
      </UberWrapper>
    </>
  );
}
