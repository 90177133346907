import React from "react";
import HomePage from "../components/Home/index";
import UberUns from "../components/Home/Uber-uns";
import PrivatekundenC from "../components/Home/PrivatekundenC";

export default function home() {
  return (
    <>
      <HomePage />
      <UberUns />
      <PrivatekundenC />
    </>
  );
}

// bad animation handling. timing is 2x too long
