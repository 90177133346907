import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageWrapper = styled.div`
  overflow: hidden;
`;
export default function index({ src, width, height, alt }) {
  return (
    <ImageWrapper>
      <LazyLoadImage
        src={src ? src : "/images/no-image.png"}
        alt={alt}
        width={width ? width : 200}
        height={height ? height : 200}
      />
    </ImageWrapper>
  );
}
