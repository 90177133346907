import React from "react";

export default function Logo() {
  return (
    <a href="/">
      <div class="flex items-center justify-start">
        <img src="/logohorizontalwhite.webp" alt="logo" class=" w-auto h-[120px] " />
      </div>
    </a>
  );
}
