import React from "react";
import styled from "styled-components";
import Logo from "../System/Logo";
import Img from "../Image";
import { Link } from "react-router-dom";
const HeaderWrapper = styled.div`
  background: rgba(0, 0, 0, 0.33);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.6px);
  -webkit-backdrop-filter: blur(6.6px);
  width: 100%;
  height: 120px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 50;
  a {
    text-decoration: none;
  }
`;

export default function Header() {
  return (
    // class="bg-secondary fixed w-full h-[150px] z-50 "
    <HeaderWrapper>
      <div class="max-md:hidden ml-[50px]">
        <Link onClick={() => window.reload()} to="/">
          <Logo />
        </Link>
      </div>
      <div class="md:hidden ml-[30px]">
        <Link onClick={() => window.reload()} to="/">
          <Img src="/logowhite.webp" />
        </Link>
      </div>
    </HeaderWrapper>
  );
}
