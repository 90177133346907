import React from "react";
import Default from "./components/layouts/Default";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Privatkunden from "./pages/privatkunden";
import Geschäftskunden from "./pages/geschäftskunden";
import UberUns from "./pages/uber-uns";
import Kontakt from "./pages/kontakt";
import Home from "./pages/home";
import Impresum from "./pages/impresum";
import NotFound from "./pages/404";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

export default function App() {
  return (
    <BrowserRouter>
      <Default />
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/über-uns" element={<UberUns />} />
        <Route exact path="/privatkunden" element={<Privatkunden />} />
        <Route exact path="/geschäftskunden" element={<Geschäftskunden />} />
        <Route exact path="/kontakt" element={<Kontakt />} />
        <Route exact path="/impresum" element={<Impresum />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
