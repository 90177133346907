import React from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Img from "../components/Image";
import Grid from "@mui/material/Grid";
// import Atropos from "atropos/react";
// import "atropos/css";
import Agents from "../components/Home/Agents";

import { useRef } from "react";
import { useInView } from "framer-motion";

const UberWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .card {
    padding: 30px;
    margin-bottom: 80px;
  }
`;

const Drawer = styled.div`
  background: url("/uberunsbg-min.webp"),
    linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55));
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  width: 100%;
  height: 100%;
  padding-top: 180px;
  margin-bottom: 180px;
  h1 {
    text-align: center;
  }
`;

function Section({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        {children}
      </span>
    </section>
  );
}

export default function uberuns() {
  return (
    <React.StrictMode>
      <UberWrapper>
        <Drawer>
          <Section>
            <h1 class="text-4xl sm:text-7xl py-32 uppercase">
              über uns
              <Divider class="bg-white w-[80%] flex align-center justify-center  m-auto mt-5 h-1" />
            </h1>
          </Section>
        </Drawer>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {/* <Atropos
                shadow={false}
                onEnter={() => console.log("Enter")}
                onLeave={() => console.log("Leave")}
                onRotate={(x, y) => console.log("Rotate", x, y)}
                alwaysActive={true}
                highlight={true}
              >
                
              </Atropos> */}
              <Section>
                <div class="card">
                  <p>
                    Wir freuen uns darauf, Sie als Kunde und als Mensch
                    kennenlernen zu dürfen. Ob es sich um die steuerliche
                    Betreuung eines kleinen Unternehmens, um den Jahresabschluss
                    für einen Konzern oder um ein Coaching für Privatkunden
                    handelt. Sämtliche Kunden und Mandanten haben bei uns den
                    gleichen Stellenwert und werden dementsprechend
                    zuvorkommend, rasch und auf Augenhöhe behandelt.
                    Bewerbungen, private Steuererklärungen, Unterstützung im
                    Alter und Steuerberatung erhalten Sie bei uns ebenso wie
                    Beratung zur Firmengründung oder geschäftliche Finanz- oder
                    Lohnbuchhaltung. Wir bieten nur an, womit wir uns auskennen
                    und genau dies ist unser Erfolgsrezept. Selbstverständlich
                    sorgen wir durch ständige Fort- und Weiterbildungen dafür,
                    dass wir stets perfekt informiert bleiben und auf dem
                    neuesten Stand der Dinge sind. Nur so können wir eine
                    sorglose rundum Beratung für unsere Kunden und Mandanten
                    gewährleisten.
                  </p>
                </div>
              </Section>
            </Grid>
            <Agents />
            <Grid item xs={12} md={12}>
              <Section>
                <div className="quote pb-20">
                  <h1 class="text-3xl text-center">
                    " If everyone is moving forward together, then success takes
                    care of itself. "
                  </h1>
                  <br />
                  <p>Wir Arbeiten mit:</p>
                  <div class="grid grid-cols-3  h-full w-full m-auto  items-center justify-center p-8 ">
                    <div class="lg:w-[50%] p-1 h-full flex align-center justify-center m-auto ">
                      <Img
                        src="/Bexio-min.webp"
                        alt="Bexios"
                        width="100%"
                        height="100%"
                      />
                    </div>
                    <div class="lg:w-[50%] p-1 h-full flex align-center justify-center m-auto    ">
                      <Img
                        src="/optiwork-min.webp"
                        alt="Banana"
                        width="100%"
                        height="100%"
                      />
                    </div>{" "}
                    <div class="lg:w-[50%] p-1 h-full flex align-center justify-center m-auto  ">
                      <Img
                        src="./Banana-min.webp"
                        alt="Domuso"
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </div>
                </div>
              </Section>
            </Grid>
          </Grid>
        </Container>
      </UberWrapper>
    </React.StrictMode>
  );
}
