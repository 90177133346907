import React from "react";
import styled from "styled-components";
import Header from "../Header";
const Content = styled.div`
  
`;

export default function Default({ children }) {
  return (
    <>
      <Header />
      <Content>{children}</Content>
    </>
  );
}
